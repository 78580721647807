
import { defineComponent } from 'vue'
import { differenceInSeconds, formatRelative } from 'date-fns'
import { POLISH_CYCLES } from '../types/POLISH_CYCLES'
import { ROCK_DATA } from '@/constants'
import { collectTrophies } from '@/lib'

export default defineComponent({
  name: 'TheTumbler',
  data: () => {
    return {
      timerDisplay: '',
      interval: 0,
      pause: true
    }
  },
  mounted () {
    if (this.isRunning) {
      this.updateTimer()
    }
    // this.$store.commit('incrementNextStop', 5) // for debugging purposes only
    // this.$store.commit('setGodmode', true) // for some browsers, god mode doesn't seem to work. uncomment this line to force it.
  },
  unmounted () {
    clearInterval(this.interval)
  },
  watch: {
    isRunning (value: boolean) {
      const video = this.$refs.tumblerVideo as HTMLVideoElement
      if (value) {
        video.play()
      } else {
        video.pause()
      }
    }
  },
  methods: {
    updateTimer () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        const nextStop = new Date(this.$store.state.nextStop)
        const currentTime = new Date()
        const distance = differenceInSeconds(nextStop, currentTime)

        if (distance <= 0) {
          this.timerDisplay = 'Complete'
          this.$store.commit('setRunning', false)
          this.$store.commit('setCycleCompleted', true)
          this.$store.commit('setWashed', false)
          this.pause = true // pause the timer
          clearInterval(this.interval)
          return
        }

        const days = Math.floor(distance / (60 * 60 * 24))
        const hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60))
        const minutes = Math.floor((distance % (60 * 60)) / 60)
        const seconds = Math.floor(distance % 60)

        this.timerDisplay = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
      }, 1000)
    },
    updateGritCycle () {
      this.$store.commit('setCanChangeGrit', false)
      this.$store.commit('setCycleCompleted', false)
      this.$store.commit('setNextGritCycle')
    },
    startPolishing () {
      const cycle = this.$store.state.cycle
      const timeToPolish = ROCK_DATA.POLISH_CYCLE_TIMES[cycle]

      this.$store.commit('incrementNextStop', timeToPolish)
      this.$store.commit('setRunning', true)
      this.pause = false
    },
    godmodeTimeSkip () {
      // sets next stop to be 10 seconds from now
      const newTime = new Date()
      newTime.setSeconds(newTime.getSeconds() + 5)
      this.$store.commit('setNextStop', newTime)
    },
    moveToTrophy () {
      collectTrophies(this.$store)
      this.$router.push({ name: 'Trophy' })
    }
  },
  computed: {
    nextStop (): string {
      const nextStop = new Date(this.$store.state.nextStop)
      const currentTime = new Date()

      return formatRelative(nextStop, currentTime)
    },
    godmode (): boolean {
      return this.$store.state.godmode
    },
    timer (): string {
      if (this.interval === 0 && !this.pause) this.updateTimer()
      return this.timerDisplay
    },
    getCurrentGrit (): POLISH_CYCLES {
      return this.$store.state.cycle
    },
    rockExists (): boolean {
      return this.$store.state.rockLists.tumbling.length >= 3
    },
    cycleCompleted (): boolean {
      return this.$store.state.cycleCompleted
    },
    isRunning (): boolean {
      return this.$store.state.running
    },
    isUnpolished (): boolean {
      return this.$store.state.cycle === POLISH_CYCLES.NONE
    },
    isPolished (): boolean {
      return this.$store.state.cycle === POLISH_CYCLES.POLISH
    },
    canWash (): boolean {
      // can wash if rock exists, is not tumbling, has not been washed, and is not in polished state
      return this.rockExists && !this.isRunning && !this.$store.state.washed
    },
    canChangeGrit (): boolean {
      // can change grit if rock exists, is not tumbling, is washed, and is not in polished state
      return this.rockExists && !this.isRunning && this.$store.state.canChangeGrit && this.$store.state.washed && !this.isPolished
    },
    canPolish (): boolean {
      // can polish if rock exists, is not tumbling, grit is one step up from last, is washed, and is not in polished state
      return this.rockExists && !this.isRunning && !this.$store.state.canChangeGrit && this.$store.state.washed && !(this.isPolished && this.cycleCompleted)
    },
    canMoveToTrophy (): boolean {
      return this.isPolished && !this.canWash && this.cycleCompleted
    }
  }
})

